import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../layout"
import SEO from "../seo"
import { Container, Carousel, Row, Col } from "react-bootstrap"
import i18n from "../../helpers/i18n"

import "../../scss/pages/home.scss"

const urlHelper = require("../../helpers/url")

const HomeArtwork = ({ lang, artworks }) => {
  const [isClient, setClient] = useState(false)
  const [randomisedArtwork, setRandomisedArtwork] = useState(false)

  if (randomisedArtwork === false) {
    setRandomisedArtwork(artworks.sort(() => 0.5 - Math.random()).slice(0, 12))
  }

  useEffect(() => {
    setClient(true)
  }, [])

  if (!isClient) {
    return ""
  }

  return randomisedArtwork.map(artwork => {
    const artistUid = artwork.node.data.artist.uid
    const artworkUrl = urlHelper(
      lang,
      "artwork",
      artistUid + "/" + artwork.node.uid
    )
    const artistUrl = urlHelper(lang, "artist", artistUid)
    const artworkImageUrl = artwork.node.data.primary_image.localFile
      ? artwork.node.data.primary_image.localFile.childImageSharp.resize.src
      : "#"

    return (
      <Col key={artwork.node.uid} md="3">
        <a
          className="artwork"
          href={artworkUrl}
          title={artwork.node.data.title.text}
        >
          <img src={artworkImageUrl} alt={artwork.node.data.title.text} />
        </a>
        <a className="artist" href={artistUrl}>
          {artwork.node.data.artist.document.data.name.text}
        </a>
      </Col>
    )
  })
}

const HomeProjectCards = ({ cards }) =>
  cards.map(card => {
    return (
      <Col key={card.title.text}>
        <div className="gls-card">
          <i className={card.icon.text}></i>
          <strong>{card.title.text}</strong>
          <span>{card.caption.text}</span>
          <a className="gls-button" href={card.link.text}>
            {card.button.text}
          </a>
        </div>
      </Col>
    )
  })

const HomeObjectivesCards = ({ lang, cards }) =>
  cards.map(card => {
    return (
      <Col key={card.title.text}>
        <div className="gls-card">
          <div className="gls-card-content">
            <Row>
              <Col md="3">
                <i className={card.icon.text}></i>
              </Col>
              <Col md="9">
                <strong className="gls-title">{card.title.text}</strong>
                <strong>{card.subtitle.text}</strong>
              </Col>
            </Row>
            <div dangerouslySetInnerHTML={{ __html: card.caption.html }}></div>
          </div>
        </div>
      </Col>
    )
  })

const Home = ({ data: { prismicHome, allPrismicArtwork } }) => {
  const { lang, data } = prismicHome
  i18n.setLanguage(lang)

  const collectionUrl = urlHelper(lang, "artwork")

  return (
    <Layout lang={lang}>
      <SEO title="Home" keywords={[`lasfera`, `gallery`, `galleria`]} />
      <Container>
        <Carousel indicators={false} controls={false}>
          <Carousel.Item>
            <img
              className="d-none d-md-block w-100"
              src={data.banner_image.url}
              alt={data.banner_title.alt}
            />
            <img
              className="d-block d-md-none w-100"
              src={data.banner_image_mobile.url}
              alt={data.banner_image_mobile.alt}
            />
            <Carousel.Caption>
              <h3 className="gls-title">{data.banner_title.text}</h3>
              <div
                className="gls-subtitle"
                dangerouslySetInnerHTML={{ __html: data.banner_subtitle.html }}
              />
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <Row className="home-artwork no-gutters">
          <HomeArtwork lang={lang} artworks={allPrismicArtwork.edges} />
        </Row>
        <Row className="home-artwork-all no-gutters">
          <Col md="12" className="text-center">
            <a
              className="gls-button"
              href={collectionUrl}
              alt={data.artwork_cta.text}
            >
              {data.artwork_cta.text}
            </a>
          </Col>
        </Row>

        <div>
          <h3 className="gls-title text-center">{data.project_title.text}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: data.project_subtitle.html }}
          />
        </div>

        <Row className="home-project-cards">
          <HomeProjectCards cards={data.project_cards} />
        </Row>

        <div className="text-center">
          <h3 className="gls-title">{data.objectives_title.text}</h3>
          <div
            className="gls-subtitle"
            dangerouslySetInnerHTML={{ __html: data.objectives_subtitle.html }}
          />
        </div>

        <Row className="home-objectives-cards">
          <HomeObjectivesCards cards={data.objectives_cards} />
        </Row>
      </Container>
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query HomeByLang($lang: String!) {
    prismicHome(lang: { eq: $lang }) {
      lang
      data {
        banner_image {
          url
          alt
        }
        banner_image_mobile {
          url
          alt
        }
        banner_title {
          text
        }
        banner_subtitle {
          html
        }
        artwork_cta {
          text
        }
        project_title {
          text
        }
        project_subtitle {
          html
        }
        project_cards {
          icon {
            text
          }
          title {
            text
          }
          caption {
            text
          }
          button {
            text
          }
          link {
            text
          }
        }
        objectives_title {
          text
        }
        objectives_subtitle {
          html
        }
        objectives_cards {
          icon {
            text
          }
          title {
            text
          }
          subtitle {
            text
          }
          caption {
            html
          }
        }
      }
    }
    allPrismicArtwork(
      filter: { lang: { eq: $lang } }
      limit: 36
      sort: { order: DESC, fields: data___price }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            primary_image {
              localFile {
                childImageSharp {
                  resize(
                    fit: COVER
                    cropFocus: CENTER
                    width: 300
                    height: 300
                  ) {
                    src
                  }
                }
              }
            }
            artist {
              uid
              document {
                ... on PrismicArtist {
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
